import React from "react"
import { graphql } from "gatsby"
import { Box } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import TitleAndBody from "../components/blocks/titleAndBody"
import PageCarousel from "../components/blocks/pageCarousel"
import Image from "../components/blocks/image"
import ImageGallery from "../components/blocks/imageGallery"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import Accordion from "../components/blocks/accordion"
import DocumentCollection from "../components/blocks/documentCollection"
import ArticleEmbed from "../components/blocks/articleEmbed"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import { getPageSlug } from "../utils/pageSlug"
import Categories from "../components/blocks/categoryBlock"
import { useFavicon } from "../hooks/useFavicon"
import ChannelMarquee from "../components/blocks/channelMarquee"
import PinnedVideo from "../components/blocks/pinnedVideo"
import BannerList from "../components/blocks/bannerList"
import LatestVideos from "../components/blocks/latestVideosList"
import VideoCategoryList from "../components/blocks/videoCategoryList"
import PinnedCategory from "../components/blocks/pinnedCategory"
import AboutUsSection from "../components/aboutUsSection"
import NewsletterSection from "../components/newsletterSection"
import Sponsors from "../components/sponsors"
import FormEmbed from "../components/blocks/formEmbed"

const LocationsMap = loadable(
  () => import("../components/blocks/locationMap"),
  { ssr: false }
)

const Page = ({
  data: {
    page,
    site,
    videos,
    aboutUsSection,
    sponsorSection,
    newsletterSection,
    pinnedCategoryVideos,
  },
}) => {
  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
      menuType: page.slug,
    }
  })

  const sortedVideos = videos.nodes.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.meta.firstPublishedAt) - new Date(a.meta.firstPublishedAt)
  })

  const imagePreview = {
    "cogeneration-channel":
      "https://www.datocms-assets.com/72518/1666152321-cogeneration-channel-preview.jpg",
    "biogas-channel":
      "https://www.datocms-assets.com/72518/1666152328-biogas-channel-preview.jpg",
    "biomethane-rng-channel":
      "https://www.datocms-assets.com/72518/1666152326-biomethane-channel-preview.jpg",
  }

  return (
    <Layout
      pageSlug={getPageSlug(page)}
      locale={page.locale}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
        <meta property="og:image" content={imagePreview[page.slug]} />
      </HelmetDatoCms>
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "marquee" && (
            <Box
              sx={{
                mt: ["100px", "100px", 0, 0],
                borderTop: ["1px solid", "1px solid", "0px solid"],
                borderColor: "dark",
              }}
            >
              <ChannelMarquee block={block} />
            </Box>
          )}
          {block.model.apiKey === "pinned_video" && (
            <Box>
              <PinnedVideo
                video={block.video ? block.video : sortedVideos[0]}
              />
            </Box>
          )}
          {block.model.apiKey === "banner_list" && (
            <Box>
              <BannerList banners={block.banners} channel={page.slug} />
            </Box>
          )}
          {block.model.apiKey === "latest_videos_list" && (
            <Box>
              <LatestVideos videos={sortedVideos} />
            </Box>
          )}
          {block.model.apiKey === "video_category_list" && (
            <Box>
              <VideoCategoryList block={block} />
            </Box>
          )}
          {block.model.apiKey === "pinned_category" && (
            <Box>
              <PinnedCategory
                block={block}
                videos={pinnedCategoryVideos.nodes}
              />
            </Box>
          )}
          {block.model.apiKey === "title_and_body" && (
            <TitleAndBody
              title={block.content.title}
              body={block.content.body}
            />
          )}
          {block.model.apiKey === "ordered_list" && (
            <OrderedList
              title={block.title}
              subtitle={block.subtitle}
              body={block.body}
            />
          )}
          {block.model.apiKey === "page_carousel" && (
            <PageCarousel title={block.title} pages={block.pages} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel items={block.items} />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion title={block.title} items={block.items} />
          )}
          {block.model.apiKey === "document_collection" && (
            <DocumentCollection
              title={block.title}
              documents={block.documents}
              showPublicationDate={block.showPublicationDate}
            />
          )}
          {block.model.apiKey === "image" && <Image image={block.image} />}
          {block.model.apiKey === "locations_map" && (
            <LocationsMap locations={block.locations} />
          )}
          {block.model.apiKey === "form_embed" && (
            <FormEmbed
              pageTitle={page.title}
              title={block.title}
              code={block.code}
            />
          )}
          {block.model.apiKey === "embed" && (
            <ArticleEmbed
              title={block.title}
              code={block.code}
              fullWidth={block.fullWidth}
            />
          )}
          {block.model.apiKey === "image_gallery" && (
            <ImageGallery images={block.images} />
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              title={block.title}
              image={block.image}
              numbers={block.numbers}
            />
          )}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              label={block.content.label}
              subtitle={block.content.subtitle}
              title={block.content.title}
              body={block.content.body}
              image={block.image}
              rightAligned={block.rightAligned}
            />
          )}
          {block.model.apiKey === "category" && (
            <Categories
              page={page}
              title={block.title}
              description={block.description}
            />
          )}
        </Box>
      ))}

      {newsletterSection.nodes.length > 0 && (
        <Box>
          <NewsletterSection data={newsletterSection.nodes[0]} />
        </Box>
      )}

      {aboutUsSection.nodes.length > 0 && (
        <Box>
          <AboutUsSection data={aboutUsSection.nodes[0]} />
        </Box>
      )}

      {sponsorSection.nodes.length > 0 && (
        <Box>
          <Sponsors data={sponsorSection.nodes[0]} />
        </Box>
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ChannelQuery(
    $id: String!
    $locale: String!
    $pinnedCategoryId: String!
  ) {
    site: datoCmsSite {
      locales
    }
    pinnedCategoryVideos: allDatoCmsVideo(
      limit: 10
      filter: {
        channel: { pageLink: { id: { eq: $id } } }
        category: { id: { eq: $pinnedCategoryId } }
        locale: { eq: $locale }
        slug: { ne: null }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        ...AllVideoSlugLocales
        slug
        title
        description
        videoId
        videoLink
        locale
        duration
        meta {
          firstPublishedAt
        }
        thumbnail {
          desktop: gatsbyImageData(placeholder: BLURRED, width: 600)
          mobile: gatsbyImageData(
            placeholder: BLURRED

            imgixParams: { fit: "crop", ar: "16:10", h: "800" }
          )
        }
        speaker {
          id
          name
          jobTitle
          company
        }
        category {
          id
          title
          locale
          slug
          model {
            apiKey
          }
          channel {
            pageLink {
              id
              slug
            }
          }
        }
      }
    }
    videos: allDatoCmsVideo(
      limit: 10
      filter: {
        channel: { pageLink: { id: { eq: $id } } }
        locale: { eq: $locale }
        slug: { ne: null }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        ...AllVideoSlugLocales
        slug
        title
        description
        videoId
        videoLink
        locale
        duration
        meta {
          firstPublishedAt
        }
        thumbnail {
          desktop: gatsbyImageData(placeholder: BLURRED, width: 600)
          mobile: gatsbyImageData(
            placeholder: BLURRED

            imgixParams: { fit: "crop", ar: "16:10", h: "800" }
          )
        }
        speaker {
          id
          name
          jobTitle
          company
        }
        category {
          id
          title
          locale
          slug
          model {
            apiKey
          }
          channel {
            pageLink {
              id
              slug
            }
          }
        }
      }
    }
    aboutUsSection: allDatoCmsAboutUsSection(
      filter: {
        channel: { pageLink: { id: { eq: $id } } }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        title
        description
        link {
          ... on DatoCmsInternalLink {
            originalId
            anchor
            locale
            model {
              apiKey
            }
            forceUrl
            link {
              ... on DatoCmsEvent {
                id
                slug
                model {
                  apiKey
                }
              }
              ... on DatoCmsHomePage {
                locale
                model {
                  apiKey
                }
              }
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
              ... on DatoCmsVideoCategory {
                ...VideoCategoryPageDetails
              }
            }
          }
        }
      }
    }
    newsletterSection: allDatoCmsNewsletterSection(
      filter: {
        channel: { pageLink: { id: { eq: $id } } }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        title
        subtitle
        link {
          ... on DatoCmsExternalLink {
            id
            anchor
            url
            model {
              apiKey
            }
          }
        }
      }
    }
    sponsorSection: allDatoCmsSponsorSection(
      filter: { channel: { pageLink: { id: { eq: $id } } } }
    ) {
      nodes {
        sponsors {
          id
          name
          image {
            url
            gatsbyImageData(
              height: 100
              placeholder: BLURRED
              imgixParams: { sat: -100 }
            )
          }
          url
        }
      }
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsNumbersCollection {
          id
          title
          image {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsFormEmbed {
          title
          code
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          image {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          rightAligned
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          id
          image {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPinnedCategory {
          id
          category {
            ... on DatoCmsVideoCategory {
              ...VideoCategoryPageDetails
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLocationsMap {
          id
          locations {
            originalId
            id
            name
            addressCountry
            addressRegion
            addressLocality
            email
            faxNumber
            coordinates {
              latitude
              longitude
            }
            postalCode
            streetAddress
            telephone
            locale
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          id
          ...ImageGallery
        }
        ... on DatoCmsDocumentCollection {
          id
          title
          showPublicationDate
          documents {
            title
            subtitle
            documents {
              url
              title
              format
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          title
          subtitle
          body {
            blocks
            links
            value
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPinnedVideo {
          video {
            ...AllVideoSlugLocales
            slug
            title
            description
            videoId
            videoLink
            locale
            duration
            meta {
              firstPublishedAt
            }
            thumbnail {
              desktop: gatsbyImageData(placeholder: BLURRED, height: 1000)
              mobile: gatsbyImageData(
                placeholder: BLURRED

                imgixParams: { fit: "crop", ar: "16:10", h: "800" }
              )
            }
            speaker {
              id
              name
              jobTitle
              company
            }
            category {
              id
              title
              locale
              slug
              model {
                apiKey
              }
              channel {
                pageLink {
                  id
                  slug
                }
              }
            }
          }
          model {
            apiKey
          }
        }

        ... on DatoCmsMarquee {
          title
          link {
            ... on DatoCmsInternalLink {
              originalId
              anchor
              locale
              model {
                apiKey
              }
              forceUrl
              link {
                ... on DatoCmsEvent {
                  id
                  slug
                  model {
                    apiKey
                  }
                }
                ... on DatoCmsHomePage {
                  locale
                  model {
                    apiKey
                  }
                }
                ... on DatoCmsBlogPage {
                  ...BlogDetails
                }
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
                ... on DatoCmsArticleCategory {
                  ...ArticleCategoryDetails
                  ...ArticleCategoryAllSlugLocales
                }
                ... on DatoCmsVideoCategory {
                  ...VideoCategoryPageDetails
                }
              }
            }
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLatestVideosList {
          model {
            apiKey
          }
        }
        ... on DatoCmsBannerList {
          banners {
            ... on DatoCmsBannerCollection {
              id
              title
              image {
                gatsbyImageData(width: 1480, placeholder: BLURRED)
              }
              url
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsVideoCategoryList {
          videoCategory {
            ... on DatoCmsVideoCategory {
              ...VideoCategoryPageDetails
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
